<template>
  <ag-modal-container
    title="Détail des rebuts"
    width="600px"
    @close="$emit('close')"
  >
    <v-card>
      <v-list subheader style="padding-bottom:0">
        <v-list-item
          v-for="(rejection, index) in rejectedCalcData"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title v-text="rejection.cause" />
          </v-list-item-content>
          <v-list-item-action>
            {{ (rejection.count / value.length * 100).toFixed(2) }} % ({{ rejection.count }})
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </ag-modal-container>
</template>

<script>
import AgModalContainer from 'src/components/common/AgModalContainer'

export default {
  name: 'RejectedPartDetail',
  components: { AgModalContainer },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    rejectedCalcData: [],
  }),
  watch: {
    value: {
      immediate: true,
      handler () {
        this.calcRejected()
      },
    },
  },
  methods: {
    calcRejected () {
      this.rejectedCalcData = []
      const tempData = {}
      this.value.forEach(rejection => {
        if (tempData[rejection.rejectionCauses.name] === undefined) {
          tempData[rejection.rejectionCauses.name] = 0
        }
        tempData[rejection.rejectionCauses.name] += 1
      })

      for (const cause in tempData) {
        this.rejectedCalcData.push({ cause, count: tempData[cause] })
      }
      this.rejectedCalcData = this.rejectedCalcData.sort((a, b) => b.count - a.count)
    },
  },
}
</script>
