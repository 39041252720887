<template>
  <v-row class="my-0" justify="center" align="center">
    <v-col cols="auto">
      <v-menu
        v-model="fromMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fromDateFormated"
            dense
            outlined
            label="Date de début"
            prepend-icon="edit_calendar"
            hide-details
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="fromDate"
          first-day-of-week="1"
          @input="fromMenu = false"
        />
      </v-menu>
    </v-col>
    <v-col cols="auto">
      <v-menu
        v-model="toMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="toDateFormated"
            dense
            outlined
            label="Date de fin"
            prepend-icon="edit_calendar"
            hide-details
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="toDate"
          first-day-of-week="1"
          @input="toMenu = false"
        />
      </v-menu>
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="accent"
        :loading="loading"
        :disabled="!canValidate"
        @click="$emit('validate', {fromDate, toDate})"
      >
        Valider
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'DateSelection',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fromDate: null,
    toDate: null,
    fromMenu: false,
    toMenu: false,
  }),
  computed: {
    canValidate () {
      if (!this.toDate || !this.fromDate) return false
      return true
    },
    fromDateFormated () {
      return this.formatDate(this.fromDate)
    },
    toDateFormated () {
      return this.formatDate(this.toDate)
    },
  },
  mounted () {
    // eslint-disable-next-line new-cap
    this.fromDate = new this.moment().startOf('day').add(-1, 'd').format('YYYY-MM-DD')
    // eslint-disable-next-line new-cap
    this.toDate = new this.moment().startOf('day').format('YYYY-MM-DD')
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  },
}
</script>
