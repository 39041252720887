<template>
  <ag-card-container
    title="Production"
    fluid
    no-content-padding
  >
    <template #left>
      <v-btn
        :disabled="!productionPart || loadingData"
        color="success"
        fab
        x-small
        :loading="loadingExport"
        @click="exportExcel"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
      <v-menu
        v-model="settingsMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            color="accent"
            fab
            x-small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>settings</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="computedCategoryMode"
                  inset
                  hide-details
                />
              </v-list-item-action>
              <v-list-item-title>Afficher par catégories d'opération</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="workloadMode"
                  inset
                  hide-details
                />
              </v-list-item-action>
              <v-list-item-title>Afficher la production en valeurs temporelle</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="rejectionCostMode"
                  inset
                  hide-details
                />
              </v-list-item-action>
              <v-list-item-title>Afficher les rebuts en €</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
    <v-container fluid class="py-0">
      <date-selection :loading="loadingData" @validate="fetchData" />
    </v-container>
    <v-tabs
      v-if="productionPart"
      v-model="tab"
      background-color="primary"
      color="primary"
      :slider-size="5"
      slider-color="accent"
      grow
    >
      <v-tab
        v-for="type in tabList"
        :key="type.data"
        v-text="type.name"
      />
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="type in tabList"
        :key="type.data"
      >
        <v-overlay absolute :value="loadingData" z-index="10">
          <v-progress-circular
            style="text-center"
            class="my-8"
            :size="80"
            width="8"
            indeterminate
            color="primary"
          />
        </v-overlay>
        <production-table
          v-if="productionPart"
          :value="productionPart.results[type.data]"
          :category-computed-value="productionPart.categoryComputedResults[type.data]"
          :workload-mode="workloadMode"
          :computed-category-mode="computedCategoryMode"
          :rejection-cost-mode="rejectionCostMode"
          :headers="productionPart.headers[type.data]"
          :first-header="type.firstHeader"
          @showRejectionDetail="showRejectedPartDetail"
        />
      </v-tab-item>
    </v-tabs-items>
    <rejected-part-detail
      v-if="rejectedPartDetailDialog"
      v-model="rejectedPartDetailData"
      @close="rejectedPartDetailDialog = false"
    />
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import { createExcelFile } from 'src/services/drawExcelService'
import ProductionTable from '../components/ProductionTable'
import RejectedPartDetail from '../components/RejectedPartDetail'
import DateSelection from '../components/DateSelection'

export default {
  name: 'ProductionContainer',
  components: {
    AgCardContainer,
    ProductionTable,
    RejectedPartDetail,
    DateSelection,
  },
  data: () => ({
    tab: null,
    productionPart: null,
    loadingData: false,
    loadingExport: false,
    rejectedPartDetailDialog: false,
    rejectedPartDetailData: null,
    computedCategoryMode: false,
    workloadMode: false,
    settingsMenu: false,
    rejectionCostMode: false,
  }),
  computed: {
    tabList () {
      return [
        { name: 'Collodion', data: 'Collodion', firstHeader: 'operation' },
        { name: 'Bobine', data: 'Bobine', firstHeader: 'operation' },
        { name: 'Faisceau', data: 'Faisceau', firstHeader: 'operation' },
        { name: 'Petits modules', data: 'pModule', firstHeader: 'operation' },
        { name: 'Grands modules', data: 'gModule', firstHeader: 'operation' },
      ]
    },
  },
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    showRejectedPartDetail (value) {
      this.rejectedPartDetailData = value
      this.rejectedPartDetailDialog = true
    },
    async fetchData ({ fromDate, toDate }) {
      try {
        this.loadingData = true
        this.productionPart = await Repositories.part.getProduction(
          {
            fromDate, toDate,
          },
        )
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const dataArray = []
        // eslint-disable-next-line no-restricted-syntax
        for (const type of this.tabList) {
          const exportConfig = {
            columns: [
              { header: type.firstHeader, key: type.firstHeader },
            ],
            view: [{ state: 'frozen', xSplit: 1, ySplit: 1 }],
            sheetName: type.name,
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const header of this.productionPart.headers[type.data]) {
            if (this.workloadMode) {
              exportConfig.columns.push({ header, key: `${header}_workload` })
            }
            else {
              exportConfig.columns.push({ header, key: header })
            }
            if (this.rejectionCostMode) {
              exportConfig.columns.push({ header: `${header} coûts des rebuts`, key: `${header}_rejectedCost` })
            }
            else {
              exportConfig.columns.push({ header: `${header} nbr rebuts`, key: `${header}_rejected` })
            }
            exportConfig.columns.push({ header: `${header} % rebuts`, key: `${header}_rejectedP` })
          }
          if (this.workloadMode) {
            exportConfig.columns.push({ header: 'Total', key: 'totalWorkload' })
          }
          else {
            exportConfig.columns.push({ header: 'Total', key: 'total' })
          }

          const data = this.computedCategoryMode
            ? this.productionPart.categoryComputedResults[type.data]
            : this.productionPart.results[type.data]
          dataArray.push({ dataToExport: data, config: exportConfig })
        }
        let fileName = 'Production'
        if (this.computedCategoryMode) {
          fileName += '_Par catégories'
        }
        if (this.workloadMode) {
          fileName += '_En secondes'
        }
        if (this.rejectionCostMode) {
          fileName += '_En euros'
        }

        await createExcelFile(dataArray, fileName)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
  },
}
</script>
<style scoped>
.v-menu__content{
  z-index: 9999 !important;
}
</style>
