<template>
  <v-data-table
    class="fixed-column"
    :headers="localHeaders"
    fixed-header
    height="calc(100vh - 270px)"
    :items="items"
    hide-default-footer
    :item-key="firstHeader"
    disable-pagination
    disable-sort
  >
    <template v-slot:[`item`]="{ item }">
      <tr>
        <td v-for="header in localHeaders" :key="header.value" class="text-center">
          {{ item[header.value] }}
          <p
            v-if="item[header.value.replace('_workload', '') + '_rejected']"
            class="rejected-info"
            @click="$emit(
              'showRejectionDetail', item[header.value.replace('_workload', '') + '_rejectedData'])"
          >
            Rebuts :
            <span v-if="item[header.value.replace('_workload', '') + '_rejectedP']">
              {{ item[header.value.replace('_workload', '') + '_rejectedP'] }} %
            </span>
            <span v-if="rejectionCostMode">
              ({{ item[header.value.replace('_workload', '') + '_rejectedCost'] }} €)
            </span>
            <span v-else>
              ({{ item[header.value.replace('_workload', '') + '_rejected'] }})
            </span>
          </p>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'ProductionTable',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    categoryComputedValue: {
      type: Array,
      default: () => ([]),
    },
    headers: {
      type: Array,
      default: () => ([]),
    },
    firstHeader: {
      type: String,
      default: 'status',
    },
    computedCategoryMode: {
      type: Boolean,
      default: false,
    },
    workloadMode: {
      type: Boolean,
      default: false,
    },
    rejectionCostMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localHeaders () {
      const tempHeader = this.headers.map(header => ({
        text: header,
        align: 'center',
        value: this.workloadMode ? `${header}_workload` : header,
      }))
      tempHeader.unshift({
        text: this.firstHeader,
        align: 'center',
        value: this.firstHeader,
      })
      tempHeader.push({
        text: 'Total',
        align: 'center',
        value: this.workloadMode ? 'totalWorkload' : 'total',
      })
      return tempHeader
    },
    valueChoice () {
      if (this.computedCategoryMode) return this.categoryComputedValue
      return this.value
    },
    items () {
      if (this.workloadMode) {
        return this.valueChoice.map(val => {
          const newRow = {}
          for (const key in val) {
            newRow[key] = val[key]
            if (key.includes('workload') || key === 'totalWorkload') {
              newRow[key] = newRow[key] > 0
                ? this.secondsToHms(newRow[key])
                : '-'
            }
          }
          return newRow
        })
      }
      return this.valueChoice
    },
  },
  methods: {
    secondsToHms (d) {
      const val = Number(d)
      const h = Math.floor(val / 3600)
      // eslint-disable-next-line no-mixed-operators
      const m = Math.floor(val % 3600 / 60)
      const s = Math.floor(val % 3600 % 60)

      const hDisplay = h > 0 ? `${h}h ` : ''
      const mDisplay = m > 0 ? `${m}m ` : ''
      const sDisplay = s > 0 ? `${s}s` : ''
      return hDisplay + mDisplay + sDisplay
    },

  },
}
</script>
<style>
  .rejected-info {
    cursor: pointer;
    color: #d20000;
    white-space: nowrap;
  }

  .fixed-column table > tbody > tr > td:nth-child(1),
  .fixed-column table > thead > tr > th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 8 !important;
    background: #dae3f0 !important;
    white-space: nowrap !important;
  }
  .fixed-column table > thead > tr > th:nth-child(1) {
    z-index: 9 !important;
  }

  .fixed-column table > tbody > tr > td:last-child,
  .fixed-column table > thead > tr > th:last-child {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
    z-index: 8 !important;
    background: #dae3f0 !important;
    font-weight: bold;
  }
  .fixed-column table > thead > tr > th:last-child {
    z-index: 9 !important;
  }

  .fixed-column table > tbody > tr:last-child {
    background: #eaeaea !important;
    font-weight: bold;
  }

  .v-overlay--active{
    z-index: 10;
  }
  .v-navigation-drawer{
    z-index: 10;
  }
  .fixed-column table > tbody > tr > td {
    white-space: nowrap !important;
  }
</style>
